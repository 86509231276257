<template>
  <div class="mod-user">
    <div class="searchBox">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()" size="small">
        <el-form-item label="车牌号">
            <el-input v-model="dataForm.vehiclePlate" placeholder="车牌号" clearable></el-input>
        </el-form-item>
<!--        <el-form-item label="维修类型">-->
<!--          <el-select class="item-choose" v-model="dataForm.repairType"-->
<!--                     style="width: 100%;" filterable clearable placeholder="请选择"-->
<!--                     @change="getDataList">-->
<!--              <el-option-->
<!--                v-for="item in dictTypeMap.repair_type"-->
<!--                :key="item.id"-->
<!--                :label="item.name"-->
<!--                :value="item.id"-->
<!--              ></el-option>-->
<!--            </el-select>-->
<!--        </el-form-item>-->
        <el-form-item label="维修日期">
          <el-date-picker
            v-model="dataForm.dateRange"
            type="daterange" align="right" unlink-panels range-separator="-"
            size="small" value-format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="$store.state.common.pickerOptions">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="维修厂">
            <el-input v-model="dataForm.repairFactory" placeholder="请输入维修厂" clearable></el-input>
        </el-form-item>
        <el-form-item label="维修状态">
          <el-select class="item-choose" v-model="dataForm.status"
                     @change="getDataList"
                     size="small" filterable clearable placeholder="请选择">
            <el-option label="维修中" value="2"></el-option>
            <el-option label="维修完成" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()" type="primary">查询</el-button>
          <el-button size="small" type="success" @click="downLoad">导出</el-button>
          <el-button v-if="isAuth('sys:user:save')" type="primary" @click="addOrUpdateHandle('add')">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableBox">
      <el-table :height="tableHeight" ref="table"
                :row-class-name="tableRowClassName"
                @row-click="rowClick"
                :row-style="selectedHighlight"
                :data="dataList"
                :summary-method="getSummaries"
                show-summary
                border size="small"
                v-loading="dataListLoading"
                @selection-change="selectionChangeHandle"
                style="width: 100%;">
        <el-table-column prop="vehiclePlate" label="车牌号" :show-overflow-tooltip="true" width="130"></el-table-column>
        <el-table-column prop="status" label="维修状态" :show-overflow-tooltip="true" width="80">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === 2" size="small" type="danger">维修中</el-tag>
            <el-tag v-else size="small" type="success">维修完成</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="repairTime" label="送修时间" :show-overflow-tooltip="true" width="110"></el-table-column>
        <el-table-column prop="planSuccessTime" label="计划结束时间" :show-overflow-tooltip="true" width="110"></el-table-column>
        <el-table-column prop="successTime" label="实际结束时间" :show-overflow-tooltip="true" width="110"></el-table-column>
        <el-table-column label="维修时长" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.days + '天' + scope.row.hours + '时' + scope.row.minutes + '分' }}
          </template>
        </el-table-column>
        <el-table-column prop="repairNo" label="维修单号" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="repairType" label="维修类型" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="repairProject" label="维修项目" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="sendRepairUser" label="送修人" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="repairFactory" label="维修厂" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="repairReason" label="维修原因" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="repairMoney" label="维修总金额" :show-overflow-tooltip="true"></el-table-column>
<!--        <el-table-column prop="name" label="操作时间" :show-overflow-tooltip="true"></el-table-column>-->
<!--        <el-table-column prop="name" label="操作人" :show-overflow-tooltip="true"></el-table-column>-->
        <el-table-column fixed="right" width="40" label="操作">
          <template slot-scope="scope">
            <el-popover placement="bottom" popper-class="popoerOpt" class="popoerBox" trigger="click">
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle('view', scope.row)">详情</el-button>
              <el-button v-if="isAuth('sys:user:update')" type="text" size="small" @click="addOrUpdateHandle('edit', scope.row)">修改</el-button>
              <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="success(scope.row)">维修完成</el-button>
              <el-button v-if="isAuth('sys:user:delete')" type="text" size="small" @click="deleteHandle(scope.row)">删除</el-button>
              <el-button slot="reference" class="referenceBtn">
                <icon-svg name="caozuo" class="site-sidebar__menu-icon"></icon-svg>
              </el-button>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
      </el-pagination>
    </div>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <success-popup ref="success" @refreshDataList="getDataList"></success-popup>
  </div>
</template>

<script>
import AddOrUpdate from './detail/vehicle-main-detail'
import successPopup from './detailPage/success-popup'
export default {
  data () {
    return {
      dataForm: {
        repairType: null,
        dateRange: null,
        vehiclePlate: null,
        repairProject: null,
        repairFactory: null,
        status: null
      },
      tableHeight: '71vh',
      dataList: [],
      pageIndex: 1,
      pageSize: 20,
      getIndex: -1,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,
      successVisible: false,
      detailVisible: false
    }
  },
  components: {
    AddOrUpdate,
    successPopup
  },
  created () {
    this.getDataList()
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  updated () {
    this.$nextTick(() => {
      this.$refs['table'].doLayout()
    })
  },
  methods: {
    /**
     * 点击表格变色start
     */
    selectedHighlight ({ row, rowIndex }) {
      if ((this.getIndex) === rowIndex ) {
        return {
          'background-color': 'rgb(250, 195, 100)'
        }
      }
    },
    // 如果为已取消时，该行置灰
    tableRowClassName ({ row, rowIndex }) {
      row.index = rowIndex;
      if (row.status === 12) {
        return 'cancel-row'
      }
      return ''
    },
    rowClick (row) {
      this.getIndex=row.index
    },
    resetHeight () {
      // 解决elementUI table合计行初始页面不显示问题  this.height由父组件传递过来 this.theight未表格绑定的高度
      window.setTimeout(() => {
        this.tableHeight = '72vh'
      }, 1000)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      this.resetHeight()
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => {
          if (column.property && column.property.indexOf('repairMoney') > -1) {
            return this.getValue(Number(item[column.property]))
          }
        })
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return this.numFilter(this.getValue(Number(prev) + curr))
            } else {
              return this.numFilter(this.getValue(prev))
            }
          }, 0)
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    getAllSummaries (totalSummary) {
      for (let c in totalSummary) {
        totalSummary[c] = this.getValue(totalSummary[c])
      }
      // 合计参数，按顺序返回
      let param = ['合计', '', '', '', '', '', '', '', '', '', '', '', totalSummary.repairMoney, '']
      // 创建列表行
      let tr = document.createElement('tr')
      // 删除上一条合计
      if (document.querySelector('.el-table__footer-wrapper table tbody').childElementCount === 2) {
        document.querySelector('.el-table__footer-wrapper table tbody').lastChild.remove()
      }
      // td赋值
      param.forEach((item, index) => {
        let td = document.createElement('td')
        td.innerHTML = item
        tr.appendChild(td)
      })
      // 添加合计
      document.querySelector('.el-table__footer-wrapper table tbody').appendChild(tr)
    },
    /**
     * 点击表格变色end
     */
    downLoad () {
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.repairTimeBegin = dateRange[0]
        timeParam.repairTimeEnd = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehicleRepair/export'),
        method: 'get',
        responseType: 'blob',
        params: this.$http.adornParams({
          'repairTimeBegin': timeParam.repairTimeBegin,
          'repairTimeEnd': timeParam.repairTimeEnd,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'repairType': this.dataForm.repairType,
          'status': this.dataForm.status,
          'repairProject': this.dataForm.repairProject,
          'repairFactory': this.dataForm.repairFactory
        })
      }).then(({ data }) => {
        const blob = data
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = (e) => {
          const a = document.createElement('a')
          a.download = '维修管理列表.xls'
          a.href = e.target.result
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 获取数据列表
    getDataList () {
      this.dataListLoading = true
      let dateRange = this.dataForm.dateRange
      let timeParam = {}
      if (dateRange && dateRange.length === 2) {
        timeParam.repairTimeBegin = dateRange[0]
        timeParam.repairTimeEnd = dateRange[1]
      }
      this.$http({
        url: this.$http.adornUrl('/vehicleRepair/findList'),
        method: 'get',
        params: this.$http.adornParams({
          'page': this.pageIndex,
          'limit': this.pageSize,
          'repairTimeBegin': timeParam.repairTimeBegin,
          'repairTimeEnd': timeParam.repairTimeEnd,
          'vehiclePlate': this.dataForm.vehiclePlate,
          'repairType': this.dataForm.repairType,
          'status': this.dataForm.status,
          'repairProject': this.dataForm.repairProject,
          'repairFactory': this.dataForm.repairFactory
        })
      }).then(({ data }) => {
        if (data) {
          this.dataList = data.list
          this.totalPage = data.total
          this.getAllSummaries(data.summaryTotal || {})
        } else {
          this.dataList = []
          this.totalPage = 0
        }
        this.dataListLoading = false
      })
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList()
    },
    // 多选
    selectionChangeHandle (val) {
      this.dataListSelections = val
    },
    // 详情
    detail (id) {
      this.detailVisible = true
      this.$nextTick(() => {
        this.$refs.detail.init(id)
      })
    },
    // 新增 / 修改
    addOrUpdateHandle (todo, row) {
      this.$router.push({
        name: 'maintenancePage',
        params: {
          row: row,
          todo: todo
        }
      })
      // this.addOrUpdateVisible = true
      // this.$nextTick(() => {
      //   this.$refs.addOrUpdate.init(todo, id)
      // })
    },
    // 维修完成
    success (row) {
      this.successVisible = true
      this.$nextTick(() => {
        this.$refs.success.init(row)
      })
    },
    // 删除
    deleteHandle (row) {
      this.$confirm('确定对【' + row.vehiclePlate + '】进行删除操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/vehicleRepair/delete/' + row.id),
          method: 'delete'
        }).then(({ data }) => {
          if (data) {
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
              onClose: () => {
                this.getDataList()
              }
            })
          } else {
            this.$message.error('操作失败')
          }
        })
      }).catch(() => {})
    }
  }
}
</script>
